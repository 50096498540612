#price-pill-wrapper {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: "Roboto Regular";

    #separator-default {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .sup-texts {
        p {
            font-weight: 400;
            font-size: 24px;
            line-height: 1.4;

            &:last-child {
                margin-top: 8px;
                color: var(--gray);
                font-weight: 400;
                font-size: 16px;
                line-height: 1.4;
            }
        }
    }

    .sub-texts {
        display: flex;
        align-items: center;
        align-self: flex-end;

        .title {
            color: var(--gray);
            font-weight: 300;
            font-size: 14px;
            text-transform: uppercase;
            line-height: 1.4;
        }

        .price {
            margin-left: 8px;
            color: var(--charcoal);
            font-weight: 600;
            font-size: 18px;
            line-height: 1.4;
        }

        .per-day {
            color: var(--gray);
            font-weight: 400;
            font-size: 16px;
            line-height: 1.4;
        }
    }
}
