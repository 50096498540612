#carousel-default {
    position: relative;
    width: 100%;
    height: calc(100vh - 160px);
    //max-height: 600px;
    overflow: hidden;

    &::before {
        position: absolute;
        z-index: 2;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: linear-gradient(0deg, #00000075 0%, #ffffff00 30%);
    }

    .text-over-image {
        position: absolute;
        z-index: 1;
        bottom: 20px;
        left: 12px;
    }

    .swiper-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .swiper-button-prev,

    .swiper-button-next {
        position: absolute;
        z-index: 1;
        top: 50%;
        background-color: var(--white);
        transform: translateY(-50%);

        &.swiper-button-prev {
            left: 0;
            border-radius: 0 8px 8px 0;

            &:after {
                color: var(--black);
                font-size: 15px;
            }
        }

        &.swiper-button-next {
            right: 0;
            border-radius: 8px 0 0 8px;

            &:after {
                color: var(--black);
                font-size: 15px;
            }
        }
    }

    .carousel-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        [alt="house in carousel"] {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        #smoky-container {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    .swiper-pagination {
        position: absolute;
        bottom: 4%;
        left: -52px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .swiper-pagination-bullet {
            z-index: 2;
            margin: 0 5px;
            border-radius: 8px;
            width: 8px;
            height: 8px;
            background-color: var(--white);
            opacity: 0.35;
            transition: width 0.3s, background-color 0.3s;

            &-active {
                width: 24px;
                background-color: var(--white);
                opacity: 1;
            }
        }
    }
    .swiper-button-prev,

    .swiper-button-next {
        display: none;
        color: #000;

        &:after {
            font-size: 20px;
        }
    }

    .yacht-info-text {
        position: absolute;
        z-index: 2;
        bottom: 50px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 24px;
        //margin: 0 32px;
        //max-width: 340px;

        #title-paragraph {
            display: none;
        }

        #button-default {
            }
        }

        .carrousel-file{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media only screen and (min-width: 48em) {
            .swiper-pagination {
                position: absolute;
                bottom: 3%;
                left: -80px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                .swiper-pagination-bullet {
                    margin: 0 5px;
                    border-radius: 8px;
                    width: 8px;
                    height: 8px;
                    background-color: var(--white);
                    opacity: 0.35;
                    transition: width 0.3s, background-color 0.3s;

                    &-active {
                        width: 24px;
                        background-color: var(--white);
                        opacity: 1;
                    }
                }
            }

            .yacht-info-text {
                position: absolute;
                margin: 0 0 0 100px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                max-width: 420px;
                margin: 0 32px;

                #title-paragraph {
                    display: block;
                }

                #button-default {
                    width: 310px;
                }
            }
        }

        @media only screen and (min-width: 80em) {
            //height: 600px;

            .swiper-pagination {
                position: absolute;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                .swiper-pagination-bullet {
                    margin: 0 5px;
                    border-radius: 8px;
                    width: 8px;
                    height: 8px;
                    background-color: var(--white);
                    opacity: 0.35;
                    transition: width 0.3s, background-color 0.3s;

                    &-active {
                        width: 24px;
                        background-color: var(--white);
                        opacity: 1;
                    }
                }
            }

            .yacht-info-text {
                position: absolute;
                bottom: 80px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                max-width: 420px;
            }
        }
    }
