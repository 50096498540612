#button {
    position: relative;
    border: none;
    border-radius: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 40px;
    color: var(--white);
    font-family: "Montserrat Variable";
    font-weight: 500;
    font-size: 0.75em;
    //height: 48px;
    text-align: center;
    align-content: center;
    text-transform: uppercase;
    line-height: 1.4;
    //letter-spacing: 2px;
    outline: none;
    overflow: hidden;
    cursor: pointer;
    transition: all ease 0.3s;

    [alt="arrow left"] {
        padding: 8px;
    }

    // &:last-child {
        //   margin-bottom: 16px;
        // }

        &:after {
            position: absolute;
            top: 0;
            left: 50%;
            width: 0;
            height: 100%;
            display: block;
            content: "";
            background: rgba(255, 0, 122, 0.1);
            opacity: 1;
            transition: all ease 0.3s;
        }

        &.primary {
            background-color: var(--royal);
            color: var(--white);
            font-size: 12px;
            border-top: 1px solid rgba(255, 255, 255, 0.5);

            &:hover {
                background-color: lighten(#03425d, 8%);
            }
        }

        &.secondary {
            position: relative;
            background: linear-gradient(135deg, var(--royal), var(--royal));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:before {
                position: absolute;
                z-index: 1;
                border-radius: 24px;
                padding: 1px;
                content: "";
                inset: 0;
                background: linear-gradient(135deg, var(--royal), var(--royal));
                -webkit-mask: linear-gradient(#000 0 0) content-box,
                linear-gradient(#000 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
            }
        }

        &.tertiary {
            background-color: var(--white);
            color: var(--royal);

            &:hover {
                background-color: var(--dust);
            }
        }

        &.quaternary {
            position: relative;
            color: var(--royal);
            background: none;

            &:hover {
                cursor: pointer;
            }

            &:after {
                display: none;
            }
        }

        &.quintenary {
            position: relative;
            background: linear-gradient(135deg, var(--white), var(--white));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:before {
                position: absolute;
                z-index: 1;
                border-radius: 24px;
                padding: 1px;
                content: "";
                inset: 0;
                background: linear-gradient(135deg, var(--white), var(--white));
                -webkit-mask: linear-gradient(#000 0 0) content-box,
                linear-gradient(#000 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
