#landing-page {
  .boat-info-wrapper {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    gap: 40px;

    .title-paragraph-wrapper {
      margin-top: 80px;
      margin-bottom: 20px;
      text-align: center;
      max-width: 490px;
    }

    .instant-book-button-container {
      width: 100%;
      max-width: 310px;
    }

    .video-player {
      border: none;
      border-radius: 24px;
      width: 100%;
      height: auto;
      max-width: 1224px;
      aspect-ratio: 16/9;
    }

    .cards-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
    }

    #card-wrapper {
      max-width: 390px;
    }
  }
}

.landing-page {
  display: grid;

  .separator-padding {
    padding-left: 4%;
  }

  .form-reserve-carousel {
    position: relative;
    display: grid;

    #form-reserve-experience {
      order: 2;
    }

    #carousel-default {
      height: 420px;
      order: 1;
    }
  }

  @media only screen and (min-width: 48em) {
    .form-reserve-carousel {
      // grid-template-columns: auto auto;

      #form-reserve-experience {
        margin: 0 auto;
        //   padding: 40px 80px;
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: center;
        //   align-items: center;
      }

      #carousel-default {
        height: 720px;
        //   order: 2;
      }
    }
  }

  @media only screen and (min-width: 80em) {
    .form-reserve-carousel {
      grid-template-columns: auto auto;

      #form-reserve-experience {
        padding: 80px 120px 0;
        order: 1;
      }

      #carousel-default {
        order: 2;
      }
    }
  }
}
