#featured-carousel {
    position: relative;
    width: 100vw;
    height: 100vh;
    // max-height: 600px;

    .video-player-banner {
        width: 100%;
        height: 100%;
        background-color: #000;
        object-fit: cover;
        background: url("../../../assets/images/banner/yacht_charter_miami_nx40_preload.png")
        no-repeat center center;
        background-size: cover;
    }

    .swiper-wrapper {
        display: flex;
        align-items: center;
    }

    &::before {
        position: absolute;
        z-index: 2;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0, 0, 0, 0.15);
    }

    .swiper-pagination {
        position: absolute;
        bottom: 4%;
        left: -52px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .swiper-pagination-bullet {
            z-index: 2;
            margin: 0 5px;
            border-radius: 8px;
            width: 8px;
            height: 8px;
            background-color: var(--white);
            opacity: 0.35;
            transition: width 0.3s, background-color 0.3s;

            &-active {
                width: 24px;
                background-color: var(--white);
                opacity: 1;
            }
        }
    }
    .swiper-button-prev,

    .swiper-button-next {
        color: #000;

        &:after {
            font-size: 20px;
        }
    }

    .featured-carousel-container {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .logo-container {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translate(-50%, -50%);
        scale: 1;

        img {
            margin-bottom: 40px;
        }

        p {
            margin-bottom: 40px;
            color: var(--white);
            font-family: "Roboto Regular";
            font-size: 18px;
            line-height: 1.4;
            max-width: 424px;
            text-align: center;
        }
    }

    [alt="trademark logo"] {
        position: absolute;
    }

    .yacht-info-text {
        position: absolute;
        z-index: 2;
        bottom: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 24px;
        max-width: 420px;

        #title-paragraph {
            display: none;
        }

        .button-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    @media only screen and (min-width: 48em) {
        .yacht-info-text {
            left: 40px;
            padding: 0;
            gap: 24px;

            #title-paragraph {
                display: block;

                #paragraph-component {
                    color: rgba(255, 255, 255, 0.75);
                }
            }

            .button-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 16px;
            }
        }

        .logo-container {
            transform: translate(-50%, -50%);
            scale: 1;
        }
    }

    @media only screen and (min-width: 80em) {
        .yacht-info-text {
            bottom: 80px;
            left: 60px;
        }

        .logo-container {
            transform: translate(-50%, -50%);
            scale: 1;
        }
    }
}
