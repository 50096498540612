html,

html * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: none;
    appearance: none;
    -webkit-appearance: none;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
}
// html,

// body {
    //     margin: 0;
    //     width: 100%;
    //     padding: 0;
    //     overflow-x: hidden;
    // }

    body {
        font-size: 16px;
        overflow-y: auto;
        overflow-x: hidden;
        h1,
        h2,
        h3,
        h4,
        h5,

        h6 {
            margin-bottom: 8px;
            color: var(--black);
            font-family: "Roboto Regular";
            font-weight: 300;
            text-transform: none;

            strong {
                font-weight: 400;
            }

            &.no-margin-top {
                margin: 0 0 16px 0;
            }

            &.no-margin {
                margin: 0;
            }

            &.more-margin {
                margin: 24px 0;
            }
        }

        h1 {
            font-size: 2.5rem;
            //40px
            line-height: 1.2;
        }

        h2 {
            font-size: 2rem;
            //32px
            line-height: 1.2;
        }

        h3 {
            font-size: 1.75rem;
            //28px
            line-height: 1.4;

            &.disclaimer {
                font-size: 1.5rem;
                //24px
            }
        }

        h4 {
            font-size: 1.25rem;
            //20px
            line-height: 1.4;
        }

        h5 {
            font-size: 1rem;
            //16px
            line-height: 1.4;
        }

        h6 {
            font-size: 0.875rem;
            //14px
            line-height: 1.4;
        }

        a {
            color: var(--blue-light);
            text-decoration: none;
        }

        p {
            color: var(--gray);
            font-family: "Roboto Regular";
            font-size: 0.875rem;
            line-height: 1.4;

            &.description {
                font-size: 1rem;
            }

            &.disclaimer {
                color: var(--gray);
                font-size: 0.75rem;
                //12px
            }
        }

        .montserrat {
            font-family: "Montserrat Variable";
        }

        .roboto-regular {
            font-family: "Roboto Regular";
        }

        .roboto-bold {
            font-family: "Roboto Bold";
        }

        // &.no-scroll {
            //     overflow: hidden;
            // }

            button {
                font-size: 0.75em;
            }

            hr {
                margin: 40px auto;
                border: none;
                width: calc(100% - 48px);
                max-width: 1224px;
                outline: none;
                border-bottom: 1px solid var(--faded);

                &.white {
                    border-bottom: 1px solid var(--white);
                }

                &.big-margin-bottom {
                    margin: 40px auto 80px auto;
                }

                &.pink {
                    border-bottom: 1px solid var(--pink-hot);
                }
            }

            video::-webkit-media-controls-play-button {
                display: none;
                -webkit-appearance: none;
            }

            input:focus, textarea:focus, select:focus{
                border: 1px solid var(--royal) !important;
                outline: none !important;
                box-shadow: none !important;
            }
        }
