.select-dropdown {
    .select-label {
        color: var(--gray-dark-super);
        font-family: "Roboto Regular";
        font-weight: 500;
        font-size: 12px;
        line-height: 1.4;
        text-align: left;

        &.disabled-label {
            color: var(--gray);
        }
    }

    #select-default {
        margin-top: 4px;
        border: 1px solid var(--ash);
        border-radius: 24px;
        width: 100%;
        height: 48px;
        padding: 12px 40px 12px 16px;
        color: var(--gray-dark-super);
        font-family: "Roboto Regular";
        font-size: 14px;
        background: var(--white) url("../../../assets/icons/bx-chevron-down.svg")
        no-repeat calc(100% - 16px) 50%;
        // scale: 0.95;
        cursor: pointer;

        &.disabled-select {
            background-color: #d4d4d4;
            cursor: inherit;
        }

        &.isWarning {
            color: var(--peach);
            outline: 1px solid var(--peach);
        }

        .dropdown-header {
            display: flex;
            align-items: flex-end;

            p {
                margin-left: 8px;
                align-self: center;
            }

            [alt="icon"] {
                z-index: 2;
                margin: 3px;
                width: 12px;
                height: 12px;
            }
        }

        .dropdown-list {
            position: fixed;
            z-index: 3;
            top: 50%;
            left: 50%;
            border-radius: 24px;
            width: 260px;
            display: grid;
            background-color: var(--white);
            padding: 16px;
            transform: translate(-50%, -50%);
            box-shadow: 0px 0px 20px 0px #00000057;
            grid-template-columns: 1fr 1fr;
            gap: 8px;

            li {
                border: 1px solid rgba(204, 204, 204, 0.5);
                border-radius: 16px;
                width: 100%;
                display: grid;
                align-items: center;
                background-color: var(--white);
                padding: 12px 16px;
                color: var(--gray-dark);
                font-family: "Roboto Regular";
                font-size: 14px;
                text-align: center;

                &:hover {
                    background-color: var(--chalk);
                    cursor: pointer;
                }

                &.li-disabled{
                    display: none;
                    background-color: rgba(204, 204, 204, 0.5);
                    color: var(--gray);

                    &:hover{
                        background-color: rgba(204, 204, 204, 0.5);
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 48em) {
        #select-default {
            .modal-overlay-select {
                display: none;
            }
        }
    }
}
