.modal-overlay-list {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.657);
  overflow: hidden;
}

#list-yacht-form {
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  height: 100vh;
  display: none;
  background-color: white;
  padding: 24px;
  font-family: "Roboto Regular";
  //top: 90px;
  overflow-y: auto;
  transition: all ease 0.3s;
  // border: 1px solid var(--dust);
  // bottom: 50%;

  &.show-form {
    display: block;
    animation-name: openForm;
    animation-duration: 0.3s;
    opacity: 1;
    transform: translate(0);
    visibility: visible;
    pointer-events: auto;
    overflow-y: auto;
    overflow-x: hidden;

    @keyframes openForm {
      from {
        transform: translate(100%);
        opacity: 0;
      }

      to {
        transform: translate(0%);
        opacity: 1;
      }
    }
  }

  .header-form {
    margin: 20px 0;

    [alt="close icon"] {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }

    .header-form-title {
      margin-bottom: 8px;
      font-family: "Roboto Regular";
      font-weight: 400;
      font-size: 24px;
      line-height: 1.4;
    }

    .header-form-subtitle {
      color: var(--gray);
      font-family: "Roboto Light";
      font-size: 16px;
      line-height: 1.4;
      text-align: left;
    }
  }

  .body-form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .label-input-text {
      color: var(--gray-dark-super);
      font-family: "Roboto Regular";
      font-weight: 500;
      font-size: 12px;
      line-height: 1.4;
      text-align: left;
    }

    .grid-column {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
    }

    .grid-column-phone {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 0.7fr 1fr;
      gap: 8px;
    }
  }

  .form-footer {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .footer-form-sub-button-text {
      margin-top: 8px;
      color: var(--gray);
      font-family: "Roboto Light";
      font-weight: 400;
      font-size: 12px;
      line-height: 1.4;
      text-align: center;
    }
  }

  @media only screen and (min-width: 48em) {
    top: 100px;
    border-radius: 24px;
    max-width: 390px;
    max-height: 780px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
